import React from "react";

// external components
import Fab from "@mui/material/Fab";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import "./fabPhone.css";

const FabPhone: React.FC = () => {
    return (
        <>
        <div className="fab-phone-extended">
            <Fab variant="extended">
                <a href="tel:+1-469-317-3997" style={{ display: "flex"}} >
                    <LocalPhoneIcon sx={{ mr: 1 }} />
                    <p id="give-us-a-call">
                    Give us a Call
                    </p>
                </a>
            </Fab>
        </div>
        <div className="fab-phone-circle">
            <Fab variant="circular">
                <a href="tel:+1-469-317-3997">
                    <LocalPhoneIcon />
                </a>
            </Fab>
        </div>
        </>
  )
}

export default FabPhone;