import * as React from 'react';

import './pageNotFound.css';

interface Props {
    setContactForm: (value: boolean) => void;
    setInfoPage: (value: boolean) => void;
}

const PageNotFound: React.FC<Props> = ({ setContactForm, setInfoPage }) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        setContactForm(true);
        setInfoPage(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='Center-notFound'>
            <h1>Error: 404 Page Not Found</h1>
        </div>
    )
}

export default PageNotFound;