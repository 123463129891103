import * as React from "react";

// internal components
import SubGridItem from "../SubGridItem/SubGrid1Item";

import cooking from "../../img/cooking.png";
import dishwasher from "../../img/dishwasher.png";
import laundry from "../../img/laundry.png";
import refrigeration from "../../img/refrigeration.png";
import "./subgrid1.css";

// Interfaces
import FormI from "../../interfaces/FormI";
interface Props {
  setFormData: React.Dispatch<React.SetStateAction<FormI>>;
  setContactForm: (value: boolean) => void;
}

const SubGrid1: React.FC<Props> = ({ setFormData, setContactForm }) => {
  return (
    <div className="sub-grid-select">
      <h3>Select Your Service Type:</h3>
      <div className="sub-grid-1">
        <SubGridItem
          type="refrigeration"
          image={refrigeration}
          setFormData={setFormData}
          setContactForm={setContactForm}
        />
        <SubGridItem
          type="laundry"
          image={laundry}
          setFormData={setFormData}
          setContactForm={setContactForm}
        />
        <SubGridItem
          type="cooking"
          image={cooking}
          setFormData={setFormData}
          setContactForm={setContactForm}
        />
        <SubGridItem
          type="dishwasher"
          image={dishwasher}
          setFormData={setFormData}
          setContactForm={setContactForm}
        />
      </div>
    </div>
  );
}

export default SubGrid1;