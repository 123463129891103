import * as React from "react";
import { useNavigate } from "react-router-dom";

// external components
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material";

import zipCodes from "../../zipCodes.json";
import "./mapselect.css";

// Interfaces
import FormI from "../../interfaces/FormI";
interface Props {
  formData: FormI;
  setFormData: React.Dispatch<React.SetStateAction<FormI>>;
  setContactForm: (value: boolean) => void;
}

const MapSelect: React.FC<Props> = ({ formData, setContactForm, setFormData }) => {

  const navigate = useNavigate();

  const handleSelect = (e: SelectChangeEvent) => {
    setContactForm(true);
    navigate("/contactform");
    setFormData((prevData: FormI) => {
      return { ...prevData, [e.target.name]: e.target.value }
    })
  }

  return (
    <div className="map-select">
      <h3>Select Your ZIP Code:</h3>
      <FormControl fullWidth>
        <InputLabel id="map-zipcodedropdown">Zip Code:</InputLabel>
        <Select
          className="customcss-muidropdown"
          labelId="map-zipcodedropdown"
          name="zipCode"
          value={formData?.zipCode || ""}
          onChange={handleSelect}
          label="Zip Code:"
        >
          <MenuItem value="not listed">Zip Code Not Listed</MenuItem>
          {zipCodes.map((item) => {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}

export default MapSelect;