import * as React from "react";

// external components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import "./formSubmitModal.css";

interface Props {
    openModal: boolean,
    handleCloseModal: () => void,
    handleAccept: () => void,
}

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
  };

const FormSubmitModal: React.FC<Props> = ({ openModal, handleCloseModal, handleAccept }) => {
    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
        >
            <Box sx={style} className="modal-box" >
                <h1 className="attention">ATTENTION:</h1>
                <p className="modal-content">
                    By pressing the <u style={{ fontWeight: "700"}}>Accept</u> button below and submitting this form, you authorize Appliance Authority to use your personal information provided in order to contact you and provide service. We will not store or share any of the information provided.
                </p>
                <br />
                <p className="modal-content">
                    If you press <u style={{ fontWeight: "700"}}>Decline</u>, we will not see your personal information nor be able to contact you.
                </p>
                <div style={{display: "flex", justifyContent: "space-around", marginTop: "20px"}}>
                    <Button size="large" variant="contained" color="success" onClick={handleAccept}>Accept</Button>
                    <Button size="large" variant="contained" color="error" onClick={handleCloseModal}>Decline</Button>
                </div>
            </Box>
        </Modal>
    )
}

export default FormSubmitModal;