import * as React from "react";
import { useNavigate } from "react-router-dom";

// external components
import HomeIcon from "@mui/icons-material/Home";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import Button from "@mui/material/Button";

import "./navbarfooter.css";

interface Props {
  contactForm: boolean;
  infoPage: boolean;
  setContactForm: (value: boolean) => void;
  setInfoPage: (value: boolean) => void;
}

const NavBarFooter: React.FC<Props> = ({ contactForm, infoPage, setContactForm, setInfoPage }) => {
  let navigate = useNavigate();

  const handleClick = () => {
    let target = contactForm ? "/" : "/contactform";
    navigate(target);
    setContactForm(!contactForm);
    setInfoPage(false);
  }

  return (<div className="navbar-footer">
    {infoPage ?
    <div>
      <Button onClick={handleClick}><HomeOutlinedIcon style={{ fontSize: "40px" }} /><div>Home</div></Button >
    </div>
    : contactForm ?
      <div>
        <Button onClick={handleClick}><HomeOutlinedIcon style={{ fontSize: "40px" }} /><div>Home</div></Button >
        <Button onClick={handleClick}><ArticleIcon style={{ fontSize: "40px" }} /><div>Contact</div></Button >
      </div>
      :
      <div>
        <Button onClick={handleClick}><HomeIcon style={{ fontSize: "40px" }} /><div>Home</div></Button >
        <Button onClick={handleClick}><ArticleOutlinedIcon style={{ fontSize: "40px" }} /><div>Contact</div></Button >
      </div>
    }
  </div>)
}

export default NavBarFooter;