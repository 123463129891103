import React from "react";
import { Routes, Route } from "react-router-dom";

// internal components
import Header from './components/Header/Header';
import Grid from './components/Grid/Grid';
import ContactForm from './components/ContactForm/ContactForm';
import InformationPage from './components/InformationPage/InformationPage';
import NavBarFooter from "./components/NavBarFooter/NavBarFooter";
import useLocalStorage from "./hooks/useLocalStorage";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import Footer from "./components/Footer/Footer";

const App: React.FC = () => {
  const [contactForm, setContactForm] = React.useState<boolean>(false);
  const [infoPage, setInfoPage] = React.useState<boolean>(false);
  const [formData, setFormData] = useLocalStorage("formData", {
    fullName: '',
    phoneNumber: '',
    zipCode: '',
    problem: '',
    email: '',
    address: '',
    appliance: '',
    applianceDetails: '',
    warranty: false,
  });

  return (
    <div className="App">
      <Header contactForm={contactForm} setInfoPage={setInfoPage} />
      <Routes>
        <Route
          path="/"
          element={
            <Grid
              formData={formData}
              setFormData={setFormData}
              setContactForm={setContactForm}
              setInfoPage={setInfoPage}
            />
          }
        />
        <Route
          path="contactform"
          element={
            <ContactForm
              formData={formData}
              setFormData={setFormData}
              setContactForm={setContactForm}
            />
          }
        />
        <Route
          path="information"
          element={
            <InformationPage />
          }
        />
        <Route 
          path="*"
          element={
            <PageNotFound 
              setContactForm={setContactForm}
              setInfoPage={setInfoPage}
            />
          }
        />
      </Routes>
      <Footer setContactForm={setContactForm} setInfoPage={setInfoPage} />
      <NavBarFooter
        contactForm={contactForm}
        infoPage={infoPage}
        setContactForm={setContactForm}
        setInfoPage={setInfoPage}
      />
    </div>
  );
}

export default App;