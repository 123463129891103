import * as React from "react";

// external components
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material";

import zipCodes from "../../zipCodes.json";

// Interfaces
import FormI from "../../interfaces/FormI";
interface Props {
  formData: FormI;
  setFormData: React.Dispatch<React.SetStateAction<FormI>>;
}

const ZipCodeSelect: React.FC<Props> = ({ formData, setFormData }) => {

  const handleSelect = (e: SelectChangeEvent) => {
    setFormData((prevData: FormI) => {
      return { ...prevData, [e.target.name]: e.target.value }
    })
  }

  return (
    <FormControl error={formData?.zipCode ? false : true}>
      <InputLabel style={{ fontFamily: "Nunito"}} id="zip-code">Zip Code:</InputLabel>
      <Select
        labelId="zip-code"
        name="zipCode"
        value={formData?.zipCode || ""}
        onChange={handleSelect}
        label="Zip Code:"
      >
        <MenuItem value="not listed">Zip Code Not Listed</MenuItem>
        {zipCodes.map((item) => {
          return <MenuItem style={{ fontFamily: "Nunito"}} key={item} value={item}>{item}</MenuItem>
        })}
      </Select>
    </FormControl>
  )
}

export default ZipCodeSelect;