import { useState, useEffect } from 'react';

function getSavedValue(key: string, initialValue: string | Function) {
    const savedValue = localStorage.getItem(key) || "";

    if (savedValue) return JSON.parse(savedValue);

    if (initialValue instanceof Function) return initialValue();
    return initialValue;
}

export default function useLocalStorage(key: string, initialValue: any | Function) {
    const [value, setValue] = useState(() => {
        return getSavedValue(key, initialValue)
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return [value, setValue]
}